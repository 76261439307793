/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1e4dppd --style2 --full pb--60 pt--60" name={"hero"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Galerie"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"qhysnacslvi"} layout={"l9"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=350x_.JPG 350w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=660x_.JPG 660w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=860x_.JPG 860w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=2000x_.JPG 2000w"} lightbox={false}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":686}} srcSet={"https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/9ad5f4d84c4c4e4e9922b7b16cc7bb53_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/db3ae91d794948ad8f43072d3e8ec105_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":742}} srcSet={"https://cdn.swbpg.com/t/18947/db3ae91d794948ad8f43072d3e8ec105_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/db3ae91d794948ad8f43072d3e8ec105_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/db3ae91d794948ad8f43072d3e8ec105_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/db3ae91d794948ad8f43072d3e8ec105_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":722}} srcSet={"https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/97140c1074694e0cb8622d9e0bd883a8_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":700}} srcSet={"https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/ed397baa68b747ddab1645e81725ae46_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":670}} srcSet={"https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/154a5a52b2654bd69d4ff8c39ae2c6d1_s=860x_.png 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":732}} srcSet={"https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/c88de82e6c45400eacd3c95a024c6cc5_s=1400x_.png 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":740}} srcSet={"https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ee2be8f76c3f4e0798ae90281161a8d3_s=1400x_.png 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/e2f99c68a1d840ad9fcc6da654df3cc3_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":742}} srcSet={"https://cdn.swbpg.com/t/18947/e2f99c68a1d840ad9fcc6da654df3cc3_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/e2f99c68a1d840ad9fcc6da654df3cc3_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/e2f99c68a1d840ad9fcc6da654df3cc3_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/e2f99c68a1d840ad9fcc6da654df3cc3_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/ce610dca65ab4cdb8a84553dc7a5664c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/1c99799c89a64a0cbf28fa6f1eeec029_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":680}} srcSet={"https://cdn.swbpg.com/t/18947/1c99799c89a64a0cbf28fa6f1eeec029_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/1c99799c89a64a0cbf28fa6f1eeec029_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/1c99799c89a64a0cbf28fa6f1eeec029_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/1c99799c89a64a0cbf28fa6f1eeec029_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/f256ca583b484935a478ab4ba4eaacf4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/f256ca583b484935a478ab4ba4eaacf4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f256ca583b484935a478ab4ba4eaacf4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f256ca583b484935a478ab4ba4eaacf4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f256ca583b484935a478ab4ba4eaacf4_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/bb38fb030d01411c8fa82343cad1220d_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":728}} srcSet={"https://cdn.swbpg.com/t/18947/bb38fb030d01411c8fa82343cad1220d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/bb38fb030d01411c8fa82343cad1220d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/bb38fb030d01411c8fa82343cad1220d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/bb38fb030d01411c8fa82343cad1220d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":724}} srcSet={"https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":674}} srcSet={"https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/79402cf768434d59afa8de58a868391f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/dccbb4a1b4a743c887b08734df45f72d_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":682}} srcSet={"https://cdn.swbpg.com/t/18947/dccbb4a1b4a743c887b08734df45f72d_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/dccbb4a1b4a743c887b08734df45f72d_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/dccbb4a1b4a743c887b08734df45f72d_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/dccbb4a1b4a743c887b08734df45f72d_s=1400x_.png 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":694}} srcSet={"https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/898ffea8a240423db0ab04d062e26678_s=2000x_.png 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":710}} srcSet={"https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/c03c08c87340492b98ad371f72e6a583_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}